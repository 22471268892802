// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action, toJS } from 'mobx';

// Imports => Constants
import { FILE_TYPES, KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
  AcDownloadFile,
} from '@utils';

const _default = {
  options: {},
  configurations: null,
  configuration: null,
  wizard: {
    control_units: [],
    hydrohammers: [],
    power_packs: [],
  },
};

let app = {};

export class ConfigurationsStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.CONFIGURATIONS);
    // AcAutoLoad(this, KEYS.CONFIGURATION);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  configurations = null;

  @observable
  configuration = null;

  @observable
  wizard = {
    control_units: [],
    hydrohammers: [],
    power_packs: [],
  };

  @computed
  get current_configurations() {
    return toJS(this.configurations);
  }

  @computed
  get current_configurations_list() {
    if (!this.current_configurations || !this.current_configurations.data)
      return [];

    const result = new AcFormatRawDataAsList(
      this.current_configurations.data,
      {
        ID: 'id',
        NAME: 'configurations.name',
        CONTROL_UNIT: 'control_unit',
        HAMMER: 'hammer',
        POWERPACK: 'powerpack',
      },
      'name',
      false,
      {
        arrayGlue: '<br />',
      }
    );

    return result;
  }

  @computed
  get current_configuration() {
    return toJS(this.configuration);
  }

  @computed
  get current_wizard_equipment() {
    return [
      ...this.wizard.control_units,
      ...this.wizard.hydrohammers,
      ...this.wizard.power_packs,
    ];
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.configurations
      .list(params)
      .then((response) => {
        this.set(KEYS.CONFIGURATIONS, response, false);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve configurations',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.configurations
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.CONFIGURATION, response, false);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve configuration with id '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (id = null, data) => {
    this.setBusy(true);

    return app.store.api.configurations
      .store(data)
      .then(async (response) => {
        await this.set(KEYS.CONFIGURATION, response, false);

        app.store.toasters.add({
          variant: 'success',
          title: `Configuration <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  storeDryRun = (data) => {
    this.setBusy(true);

    return app.store.api.configurations
      .store(data, { dry_run: true })
      .then(async (response) => {
        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        this.setBusy(false);
        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update = (id, data) => {
    this.setBusy(true);

    return app.store.api.configurations
      .update(id, data)
      .then(async (response) => {
        await this.set(KEYS.CONFIGURATION, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: 'Configuration details saved successfully.',
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update configuration`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  updateDryRun = (id, data) => {
    this.setBusy(true);

    return app.store.api.configurations
      .update(id, data, { dry_run: true })
      .then(async (response) => {
        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        this.setBusy(false);
        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.configurations
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `Configuration <strong>${name}</strong> deleted`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete configuration <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  download = async ({ id, name }) => {
    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `Configuration file has been requested`,
      indeterminate: true,
    });

    return app.store.api.configurations
      .download(id)
      .then(async (response) => {
        const { headers, data } = response;

        let file_name = `IhcTypes.xml`;
        const disposition = headers['content-disposition'];

        if (disposition && disposition.indexOf('attachment') !== -1) {
          var pattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = pattern.exec(disposition);
          if (matches != null && matches[1]) {
            file_name = matches[1].replace(/['"]/g, '');
          }
        }

        await AcDownloadFile(data, file_name, FILE_TYPES.XML);

        app.store.toasters.update(toast.id, {
          title: 'Download is complete',
          description: `Configuration file <strong>${file_name}</strong> has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download configuration file`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  send_to_control_unit = async ({ id, name }, project) => {
    const toast = await app.store.toasters.add({
      variant: 'pending',
      title: 'Preparing to send configuration to control unit',
      description: `Sending configuration <strong>${name}</strong> to control unit`,
      indeterminate: true,
    });

    return app.store.api.configurations
      .send_to_control_unit(id, project)
      .then((response) => {
        app.store.toasters.update(toast.id, {
          title: `Configuration <strong>${name}</strong> succesfully sent to control unit`,
          variant: 'success',
          indeterminate: false,
        });

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: `Failed to send configuration <strong>${name}</strong> to control unit`,
          description: AcFormatErrorMessage(error),
          variant: 'error',
          indeterminate: false,
        });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  add_equipment = (id, data) => {
    this.setBusy(true);

    return app.store.api.configurations
      .add_equipment(id, data)
      .then(async (response) => {
        await this.set(KEYS.CONFIGURATION, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: `Selected equipment has been added`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to add the selected equipment`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        throw error;
      });
  };

  @action
  remove_equipment = (id, object) => {
    this.setBusy(true);

    return app.store.api.configurations
      .remove_equipment(id, { equipment_id: object.id })
      .then(async (response) => {
        await this.set(KEYS.CONFIGURATION, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: `Equipment <strong>${object.object_no}</strong> has been removed`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to remove equipment <strong>${object.object_no}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);
      });
  };

  @action
  add_operators = (id, operators, project_id) => {
    this.setBusy(true);

    return app.store.api.configurations
      .add_operator(id, {
        users: operators,
        project_id,
      })
      .then(async (response) => {
        await this.set(KEYS.CONFIGURATION, response, false);

        const description = `Selected operator${
          operators === 1 ? '' : 's'
        } have been assigned`;

        app.store.toasters.add({
          variant: 'success',
          description,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          const title = `Failed to assign the selected operator${
            operators === 1 ? '' : 's'
          }`;

          app.store.toasters.add({
            variant: 'error',
            title,
            description: AcFormatErrorMessage(error),
          });
        }

        this.setBusy(false);
      });
  };

  @action
  remove_operator = (id, operator, project_id) => {
    this.setBusy(true);

    return app.store.api.configurations
      .remove_operator(id, {
        users: [operator.id],
        project_id,
      })
      .then(async (response) => {
        await this.set(KEYS.CONFIGURATION, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: `Operator <strong>${operator.name}</strong> has been unassigned`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to unassign operator <strong>${operator.name}</strong>`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);
      });
  };

  @action
  setWizardData = (target, value) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(value)) return;

    this.wizard[target] = value;
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default ConfigurationsStore;
